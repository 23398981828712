import { isTestnet } from './env'

export const LOCAL_STORAGE_TRADER_SETTINGS_KEY = 'lyra-trader-settings'
export const LOCAL_STORAGE_SESSION_KEY = 'session-key'
export const LOCAL_STORAGE_SUBACCOUNT_ID_KEY = 'subaccount-id'
export const LOCAL_STORAGE_ANNOUNCEMENTS_KEY = 'announcements'
export const LOCAL_STORAGE_USER_LEVEL_ID = (isTestnet ? 'testnet-' : 'mainnet-') + 'level-'
export const LOCAL_STORAGE_POINTS_INVITE_CODE = isTestnet
  ? 'testnet-invite-code'
  : 'mainnet-invite-code'
export const LOCAL_STORAGE_DERIVE_REBRAND_POPUP_KEY = 'lyra-to-derive-popup'
