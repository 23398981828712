import { LyraAuthHeaders } from '@lyra/core/constants/api'

import { UserWithAuth } from '../constants/user'

export default function getAuthHeaders(userWithAuth: UserWithAuth): LyraAuthHeaders {
  const auth = userWithAuth.auth
  return {
    'X-LyraWallet': auth.address,
    'X-LyraTimestamp': auth.timestamp.toString(),
    'X-LyraSignature': auth.signature,
  }
}
