import {
  CollateralResponseSchema,
  PrivateGetSubaccountResultSchema,
} from '@lyra/core/api/types/private.get_subaccount'

import { MarketId } from './markets'
import { CollateralId, getEmptyCollaterals, TokenId } from './tokens'

export type Subaccount = PrivateGetSubaccountResultSchema

export type Collateral = CollateralResponseSchema

export type CreateSubaccountParams =
  | {
      marginType: 'PM'
      market: MarketId // only required for PM
      amount?: bigint
      label?: string
    }
  | {
      marginType: 'SM'
      amount?: bigint
      token?: TokenId
      market?: undefined
      label?: string
    }

export type DepositSubaccountParams =
  | {
      subaccountId: number
      marginType: 'PM'
      market: MarketId
      signatureExpirySec?: number
    }
  | {
      subaccountId: number
      marginType: 'SM'
      market: undefined
      signatureExpirySec?: number
    }

export type MarginType = 'SM' | 'PM'

export type TransferType = 'deposit' | 'transfer' | 'withdraw' | 'create'

export type TransferInput =
  | {
      type: 'deposit'
      fromSubaccountId: 'funding'
      toSubaccountId: number
    }
  | {
      type: 'transfer'
      fromSubaccountId: number
      toSubaccountId: number
    }
  | {
      type: 'withdraw'
      fromSubaccountId: number
      toSubaccountId: 'funding'
    }
  | {
      type: 'create'
      fromSubaccountId: 'funding'
      toSubaccountId: null
    }

export const SUBACCOUNT_MAX_LABEL_LENGTH = 15
export const SUBACCOUNT_MAX_COUNT = 16

export const DEFAULT_CREATE_SUBACCOUNT_PARAMS: CreateSubaccountParams = {
  marginType: 'SM',
  label: 'Account 1',
  market: undefined,
}

export const EMPTY_FREE_COLLATERAL_BALANCES = Object.values(CollateralId).reduce(
  (acc, collateral) => {
    acc[collateral] = BigInt(0)
    return acc
  },
  {} as Record<CollateralId, bigint>
)

export type SubaccountCollateralBalance = {
  // Amount of collateral deposited to subaccount, in 18dp
  balanceBn: bigint
  balance: number
  // Amount of collateral deposited to subaccount, in decimals of token (e.g. 6dp for USDC)
  depositedBalanceBn: bigint
  depositedBalance: number
  // Amount that can be withdrawn to wallet, in decimals of token (e.g. 6dp for USDC)
  withdrawableBalanceBn: bigint
  withdrawableBalance: number
  // Amount that can be transferred to another subaccount, in 18dp
  transferrableBalanceBn: bigint
  transferrableBalance: number
  // Amount of collateral that is locked in the subaccount from borrowing or open positions
  // Note: not set for USDC, and for non-USDC totalBalance = availableBalance + lockedBalance
  lockedBalanceBn: bigint
  lockedBalance: number
}

export type SubaccountData = {
  subaccount: Subaccount
  // Total collateral, adjusted for perps unrealized P&L
  collateralValue: number
  // Free collateral, or collateralValue - maintenanceMargin, margin required to keep positions open
  // maintenanceMargin must be > 0 to keep positions open
  maintenanceMargin: number
  // Avaiable buying power, or initialMargin
  buyingPower: number
  // Margin utilization, or collateralValue - maintenanceMargin / collateralValue, margin usage
  // marginUtilization must be < 100% to keep positions open
  marginUtilization: number
  // Total value of collateral and open positions
  // Conceptually this is how much you could sell your subaccount
  subaccountValue: number
  // How much USDC balance there is (ignores negative cash)
  usdcBalance: number
  // How much USDC can be withdrawn from subaccount (initial margin)
  usdcAvailableToWithdraw: number
  // How much USDC has been borrowed
  usdcBorrowed: number
  // How much USDC can be borrowed = usdcAvailableToWithdraw - usdcBalance
  usdcAvailableToBorrow: number
  // Balances
  collateralBalances: Record<CollateralId, SubaccountCollateralBalance>
  // Label with formatted default
  label: string
  // Check if subaccount is empty: no collaterals or positions
  isEmpty: boolean
}

export type EmptySubaccountData = {
  subaccount: undefined
  collateralValue: 0
  maintenanceMargin: 0
  buyingPower: 0
  marginUtilization: 0
  subaccountValue: 0
  usdcBalance: 0
  usdcAvailableToWithdraw: 0
  usdcBorrowed: 0
  usdcAvailableToBorrow: 0
  collateralBalances: Record<
    CollateralId,
    {
      totalBalance: bigint
      availableBalance: bigint
      lockedBalance: bigint
    }
  >
  label: 'Account 1'
  isEmpty: true
}

export const EMPTY_SUBACCOUNT_DATA: EmptySubaccountData = {
  subaccount: undefined,
  collateralValue: 0,
  maintenanceMargin: 0,
  buyingPower: 0,
  marginUtilization: 0,
  subaccountValue: 0,
  usdcBalance: 0,
  usdcAvailableToWithdraw: 0,
  usdcBorrowed: 0,
  usdcAvailableToBorrow: 0,
  collateralBalances: getEmptyCollaterals({
    totalBalance: BigInt(0),
    availableBalance: BigInt(0),
    lockedBalance: BigInt(0),
  }),
  label: 'Account 1',
  isEmpty: true,
}
