import { BatchUserOperationCallData } from '@alchemy/aa-core'
import { PrivateDepositParamsSchema } from '@lyra/core/api/types/private.deposit'
import { MAX_INT } from '@lyra/core/constants/contracts'
import { SECONDS_IN_HOUR } from '@lyra/core/constants/time'
import { bigNumberToString } from '@lyra/core/utils/bigNumberToString'
import erc20Abi from '@lyra/web/abis/erc20Abi'
import { BridgeTransaction, MIN_DEPOSIT_ALLOWANCE } from '@lyra/web/constants/bridge'
import { lyraChain } from '@lyra/web/constants/chains'
import { lyraClient } from '@lyra/web/constants/client'
import { lyraContractAddresses } from '@lyra/web/constants/contracts'
import { DepositSubaccountParams } from '@lyra/web/constants/subaccount'
import { TokenId } from '@lyra/web/constants/tokens'
import { Address, encodeAbiParameters, encodeFunctionData, PrivateKeyAccount } from 'viem'

import { signAction, SignActionParams } from '../actions'
import { getUtcNowSecs } from '../time'
import {
  getActiveTokens,
  getCollateralAddress,
  getCollateralForToken,
  getLyraTokenAddress,
  getTokenDecimals,
} from '../tokens'

export const signDeposit = async (
  address: Address,
  sessionKey: PrivateKeyAccount,
  token: TokenId,
  amount: bigint,
  {
    subaccountId,
    marginType,
    market,
    signatureExpirySec: signatureExpirySecInput,
  }: DepositSubaccountParams
): Promise<PrivateDepositParamsSchema> => {
  const collateral = getCollateralForToken(token)
  const collateralAddress = getCollateralAddress(collateral)

  const managerAddress =
    marginType === 'PM'
      ? lyraContractAddresses.markets[market].portfolioManager
      : lyraContractAddresses.standardManager

  if (!managerAddress) {
    throw new Error(`Margin type ${marginType} is not supported in market ${market}`)
  }

  const encodedDepositData = encodeAbiParameters(
    [{ type: 'uint256' }, { type: 'address' }, { type: 'address' }],
    [amount, collateralAddress, managerAddress]
  )

  // Valid for 1 hour
  const signatureExpirySec = signatureExpirySecInput ?? getUtcNowSecs() + SECONDS_IN_HOUR

  const args: SignActionParams = {
    chainId: lyraChain.id,
    signingAccount: sessionKey,
    owner: address,
    subaccountId,
    encodedData: encodedDepositData,
    contract: 'deposit',
    signatureExpiry: signatureExpirySec,
  }

  const { signature, signatureExpiry, signer, nonce } = await signAction(args)

  const amountStr = bigNumberToString(amount, getTokenDecimals(token))

  const depositParams = {
    amount: amountStr,
    asset_name: collateral,
    nonce,
    signature,
    signature_expiry_sec: signatureExpiry,
    signer,
    subaccount_id: subaccountId,
  }

  return depositParams
}

export const fetchBridges = async (startTimestamp?: number): Promise<BridgeTransaction[]> => {
  const res = await fetch(`/api/bridge${startTimestamp ? '?start=' + startTimestamp : ''}`, {
    cache: 'no-store',
  })
  const bridges: BridgeTransaction[] = await res.json()
  return bridges
}

export const updateUserPendingBridges = async (): Promise<BridgeTransaction[]> => {
  const res = await fetch(`/api/bridge/pending`, { cache: 'no-store' })
  if (!res.ok) {
    return []
  }
  const bridges: BridgeTransaction[] = await res.json()
  return bridges
}

export const fetchNeedsDepositApproval = async (address: Address): Promise<boolean> => {
  const [depositAllowances, paymasterAllowances] = await Promise.all([
    Promise.all(
      getActiveTokens().map((token) =>
        lyraClient.readContract({
          abi: erc20Abi,
          address: getLyraTokenAddress(token),
          functionName: 'allowance',
          args: [address, lyraContractAddresses.deposit],
        })
      )
    ),
    Promise.all(
      getActiveTokens().map((token) =>
        lyraClient.readContract({
          abi: erc20Abi,
          address: getLyraTokenAddress(token),
          functionName: 'allowance',
          args: [address, lyraContractAddresses.paymaster],
        })
      )
    ),
    // TODO: @earhttojake enable multicall
    // lyraClient.multicall({
    //   contracts: getActiveTokens().map((token) => ({
    //     abi: erc20Abi,
    //     address: getLyraTokenAddress(token),
    //     functionName: 'allowance',
    //     args: [address, lyraContractAddresses.deposit],
    //   })),
    // }),
    // lyraClient.multicall({
    //   contracts: getActiveTokens().map((token) => ({
    //     abi: erc20Abi,
    //     address: getLyraTokenAddress(token),
    //     functionName: 'allowance',
    //     args: [address, lyraContractAddresses.paymaster],
    //   })),
    // }),
  ])

  const needsApproval = [...depositAllowances, ...paymasterAllowances].some(
    (allowance) => allowance <= MIN_DEPOSIT_ALLOWANCE
  )

  return needsApproval
}

export const getApproveDepositAndWithdrawalTxs = (): BatchUserOperationCallData => {
  const txs: BatchUserOperationCallData = []

  // #2: Approve deposit module
  const lyraTokens = getActiveTokens()
  for (const lyraToken of lyraTokens) {
    const tokenAddress = getLyraTokenAddress(lyraToken)
    console.debug(`approving ${lyraToken} ${tokenAddress}`)

    // Required for deposits
    txs.push({
      target: tokenAddress,
      data: encodeFunctionData({
        abi: erc20Abi,
        functionName: 'approve',
        args: [lyraContractAddresses.deposit, MAX_INT],
      }),
    })

    // Required for self-paying withdrawals
    txs.push({
      target: tokenAddress,
      data: encodeFunctionData({
        abi: erc20Abi,
        functionName: 'approve',
        args: [lyraContractAddresses.paymaster, MAX_INT],
      }),
    })
  }

  console.debug(
    'approving contracts:',
    lyraContractAddresses.deposit,
    lyraContractAddresses.paymaster
  )

  return txs
}
