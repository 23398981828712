import { TransactionOptions } from '@lyra/web/constants/transactions'
import useAuth from '@lyra/web/hooks/useAuth'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { useCallback } from 'react'

import WalletTransactionModal from './WalletTransactionModal'

const TransactionErrorModal = () => {
  const { createAndDepositFirstSubaccount } = useTransaction()
  const { logout } = useAuth()

  const wrappedInitAccount = useCallback(
    async (options: TransactionOptions) => {
      return await createAndDepositFirstSubaccount({ ...options, createSubaccount: false })
    },
    [createAndDepositFirstSubaccount]
  )

  return (
    <WalletTransactionModal
      onClose={logout}
      transaction={wrappedInitAccount}
      reviewButtonLabel="Enable Account"
      loggerAction="recover-auth-headers"
      reviewTitle="Something went wrong"
      reviewSubtitle="An error has occurred with your session key. Please follow the instructions below to re-enable your account. If the error persists, contact support."
    />
  )
}

export default TransactionErrorModal
