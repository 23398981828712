import { X } from '@tamagui/lucide-icons'
import React from 'react'
import { Stack, StackProps, XStack, YStack } from 'tamagui'

import BodyText from '../BodyText'
import Button from '../Button'
import Icon from '../Icon'
import VideoText from '../VideoText'

export type ToastColor = 'primary' | 'green' | 'amber' | 'red'

export type ToastProps = {
  id: string
  title: string
  description: React.ReactNode
  icon: React.JSX.Element
  duration?: number
  color?: ToastColor
  onPress?: () => void
}

type Props = ToastProps & StackProps & { onClose: () => void }

const getProgressColor = (color: ToastColor): string => {
  switch (color) {
    case 'primary':
      return '$primaryText'
    case 'green':
      return '$greenText'
    case 'red':
      return '$redText'
    case 'amber':
      return '$amberText'
  }
}

export default function Toast({
  icon,
  title,
  description,
  color = 'primary',
  duration,
  onPress,
  onClose,
  ...stackProps
}: Props) {
  return (
    <XStack
      {...stackProps}
      onPress={onPress ?? onClose}
      columnGap="$1.5"
      alignItems="center"
      backgroundColor="$hoverBg"
      paddingVertical="$1"
      paddingHorizontal="$1.5"
      borderColor="$hairline"
      borderWidth={1}
      borderRadius="$1"
      position="relative"
      cursor="pointer"
      hoverStyle={{
        backgroundColor: '$pressBg',
      }}
    >
      <XStack minWidth={18}>
        <Icon color={color} icon={icon} size={18} />
      </XStack>

      <YStack flexGrow={1} flexShrink={1}>
        <VideoText color={color}>{title}</VideoText>
        <BodyText color="secondary">{description}</BodyText>
      </YStack>

      <Button
        marginLeft="auto"
        size="sm"
        onPress={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onClose()
        }}
        icon={<X />}
      />
      {duration && duration !== Infinity ? (
        <Stack
          style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          borderBottomLeftRadius={2}
          borderBottomRightRadius={2}
          flex={1}
          overflow="hidden"
        >
          <Stack
            width="100%"
            height={1}
            backgroundColor={getProgressColor(color)}
            style={{
              animation: `progress ${duration}ms linear forwards`,
              animationIterationCount: 1,
            }}
          />
        </Stack>
      ) : null}
    </XStack>
  )
}
