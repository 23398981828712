import { PrivateGetTradeHistoryResultSchema } from '@lyra/core/api/types/private.get_trade_history'
import { LyraAuthHeaders } from '@lyra/core/constants/api'
import { Address } from 'viem'

import { BridgeTransaction } from './bridge'
import { DepositNetwork } from './chains'
import { isTestnet } from './env'
import { MarketId } from './markets'
import { Points } from './points'
import { Subaccount } from './subaccount'
import { CollateralId, PartialToken } from './tokens'
import {
  LBTCCSMainnetAddresses,
  LBTCPSMainnetAddresses,
  rsETHCMainnetAddresses,
  rswETHCMainnetAddresses,
  sUSDeBULLMainnetAddresses,
  weETHBULLMainnetAddresses,
  weETHCMainnetAddresses,
  weETHCSMainnetAddresses,
} from './yield.mainnet.config'
import {
  LBTCCSTestnetAddresses,
  LBTCPSTestnetAddresses,
  rsETHCTestnetAddresses,
  rswETHCTestnetAddresses,
  sUSDeBULLTestnetAddresses,
  weETHBULLTestnetAddresses,
  weETHCSTestnetAddresses,
  weETHCTestnetAddresses,
} from './yield.testnet.config'

export type YieldTradeHistory = PrivateGetTradeHistoryResultSchema

export type YieldBridgeTransaction = BridgeTransaction & {
  type: 'predeposit-yield' | 'prewithdraw-yield' | 'deposit-yield' | 'withdraw-yield'
}

export type YieldBaseApyId = 'eth-staking'

export type YieldStrategyId =
  | 'CoveredCalls'
  | 'LongPrincipalProtected'
  | 'CoveredCallSpread'
  | 'CoveredPutSpread'

// Defines default order of vaults
export enum YieldTokenId {
  SUSDEBULL = 'SUSDEBULL',
  WEETHC = 'WEETHC',
  RSWETHC = 'RSWETHC',
  RSETHC = 'RSETHC',
  LBTCCS = 'LBTCCS',
  LBTCPS = 'LBTCPS',
  WEETHCS = 'WEETHCS',
  WEETHBULL = 'WEETHBULL',
}

export type YieldStats = {
  id: YieldTokenId
  totalSupply: number
  tvl: number
  optionsApy: number
  baseApy: number
  depositRate: number
  withdrawRate: number
  subaccount: Subaccount | null
}

export type YieldPosition = {
  id: YieldTokenId
  balance: number
  value: number
  balances: Record<DepositNetwork, number>
}

export type YieldPointsId = keyof Points

export type YieldCollateralId = 'USDC' | 'RSWETH' | 'WEETH' | 'RSETH' | 'LBTC'

export type YieldIntegratorPointsId = 'swell' | 'etherfi' | 'kelp' | 'ethena' | 'lombard'

export type YieldTokenConfig = {
  id: YieldTokenId
  addresses: YieldTokenAddresses
  pointsId: keyof Points
  integratorPointsId: YieldIntegratorPointsId
  pointsMultiplier: number
  collateralId: CollateralId // collateral in vault
  marketId: MarketId // market being traded
  contractTokenName: string
  baseApy?: YieldBaseApyId
  strategyId: YieldStrategyId
  isUnlisted?: boolean
  isEigenLayer?: boolean
  isLombard?: boolean
  isBabylon?: boolean
  isFeatured?: boolean
  isRfq?: boolean
  showPerformanceChart?: boolean
} & (
  | {
      isEarlyDeposit: false
      subaccountId: number
      authHeaders: LyraAuthHeaders
    }
  | {
      isEarlyDeposit: true
      startExecutingTimestampUTC?: Date
    }
)

export type ActiveYieldConfig = YieldTokenConfig & {
  isEarlyDeposit: false
  subaccountId: number
  authHeaders: LyraAuthHeaders
}

export type ActiveRfqYieldConfig = ActiveYieldConfig & {
  isRfq: true
}

export type InactiveYieldConfig = YieldTokenConfig & {
  isEarlyDeposit: true
  startExecutingTimestampUTC?: Date
}

export type YieldTokenInputConfig = {
  network: DepositNetwork
  inputToken: PartialToken
  connector: Address
  vault: Address
}

export type YieldTokenOutputConfig = {
  network: DepositNetwork
  outputToken: PartialToken
  connector: Address
  vault: Address
}

export type YieldTokenAddresses = {
  lyraInputToken: PartialToken
  lyraOutputToken: PartialToken
  mintConfig: {
    inputConfigs: [YieldTokenInputConfig, ...YieldTokenInputConfig[]]
    outputConfigs: [YieldTokenOutputConfig, ...YieldTokenOutputConfig[]]
  }
  redeemConfig: {
    inputConfigs: [YieldTokenInputConfig, ...YieldTokenInputConfig[]]
    outputConfigs: [YieldTokenOutputConfig, ...YieldTokenOutputConfig[]]
  }
}

export const rswETHCConfig: YieldTokenConfig = {
  id: YieldTokenId.RSWETHC,
  addresses: isTestnet ? rswETHCTestnetAddresses : rswETHCMainnetAddresses,
  pointsId: 'lrt_swell_coveredcall',
  integratorPointsId: 'swell',
  pointsMultiplier: 4.5,
  collateralId: CollateralId.RSWETH,
  marketId: MarketId.ETH,
  contractTokenName: 'rswETH Covered Call',
  isEarlyDeposit: false,
  strategyId: 'CoveredCalls',
  baseApy: 'eth-staking',
  isEigenLayer: true,
  isRfq: true,
  showPerformanceChart: true,
  subaccountId: isTestnet ? 54122 : 5739,
  authHeaders: isTestnet
    ? {
        'X-LyraWallet': '0x797Db58F4c6611253e92B9a3260E3Cc9C69430a5',
        'X-LyraTimestamp': '1718682873226',
        'X-LyraSignature':
          '1ffdc667f753b0ae44457ff949c114da8edf735b620393c6c4175c1f3f40d6f03f2e156eda497836ec46d60cc9a31cc5ab5f903097434c7f85d77223e99b24e41b',
      }
    : {
        'X-LyraWallet': '0x5bbef94dcee8f087D5146d2815bC4955C76B2794',
        'X-LyraTimestamp': '1719551753809',
        'X-LyraSignature':
          '0xc0852e937d1935ebe3c9c1e139f4364953a90b5ac92cc1a7c42db9f9a49d2ace3841d5c0e4e1dc2da696c1ede4053716972e320c76cd0fce38c81b9b9029aa831c',
      },
}

export const weETHCConfig: YieldTokenConfig = {
  id: YieldTokenId.WEETHC,
  addresses: isTestnet ? weETHCTestnetAddresses : weETHCMainnetAddresses,
  pointsId: 'lrt_etherfi_coveredcall',
  integratorPointsId: 'etherfi',
  pointsMultiplier: 3,
  collateralId: CollateralId.WEETH,
  marketId: MarketId.ETH,
  contractTokenName: 'weETH Covered Call',
  isEarlyDeposit: false,
  strategyId: 'CoveredCalls',
  baseApy: 'eth-staking',
  isEigenLayer: true,
  isRfq: true,
  showPerformanceChart: true,
  subaccountId: isTestnet ? 54122 : 5738,
  authHeaders: isTestnet
    ? {
        'X-LyraWallet': '0x797Db58F4c6611253e92B9a3260E3Cc9C69430a5',
        'X-LyraTimestamp': '1718682873226',
        'X-LyraSignature':
          '1ffdc667f753b0ae44457ff949c114da8edf735b620393c6c4175c1f3f40d6f03f2e156eda497836ec46d60cc9a31cc5ab5f903097434c7f85d77223e99b24e41b',
      }
    : {
        'X-LyraWallet': '0xec68928bd83B2E52fF5A8e8c215B6ea72879F521',
        'X-LyraTimestamp': '1719534244733',
        'X-LyraSignature':
          '0x65814a2361bd3751093eabe7380ebc6325fc981e92220836e14edf4246dc94016638c470e1382a772a5f36eda8abb39bdc118cfafcdd3a65e24bbe6cdef9f92c1b',
      },
}

export const rsETHCConfig: YieldTokenConfig = {
  id: YieldTokenId.RSETHC,
  addresses: isTestnet ? rsETHCTestnetAddresses : rsETHCMainnetAddresses,
  pointsId: 'lrt_kelp_coveredcall',
  integratorPointsId: 'kelp',
  pointsMultiplier: 2,
  collateralId: CollateralId.RSETH,
  marketId: MarketId.ETH,
  contractTokenName: 'rsETH Covered Call',
  isEarlyDeposit: false,
  strategyId: 'CoveredCalls',
  baseApy: 'eth-staking',
  isEigenLayer: true,
  isRfq: true,
  showPerformanceChart: true,
  subaccountId: isTestnet ? 54122 : 5740,
  authHeaders: isTestnet
    ? {
        'X-LyraWallet': '0x797Db58F4c6611253e92B9a3260E3Cc9C69430a5',
        'X-LyraTimestamp': '1718682873226',
        'X-LyraSignature':
          '1ffdc667f753b0ae44457ff949c114da8edf735b620393c6c4175c1f3f40d6f03f2e156eda497836ec46d60cc9a31cc5ab5f903097434c7f85d77223e99b24e41b',
      }
    : {
        'X-LyraWallet': '0xd35bb8582809b4BDa4F8bCCE1bde48559f63eCbf',
        'X-LyraTimestamp': '1719552327554',
        'X-LyraSignature':
          '0x93b61c5e0f119f2899ec6527444519a36f2f8aafa0bb2e1fd40f80865647745a70f629a123b539f0aa1a55bcf8d5a6e193a572f48e6e11b4dcb241e076aca5f61b',
      },
}

export const sUSDeBULLConfig: YieldTokenConfig = {
  id: YieldTokenId.SUSDEBULL,
  addresses: isTestnet ? sUSDeBULLTestnetAddresses : sUSDeBULLMainnetAddresses,
  pointsId: 'susde_ethena_longpp',
  integratorPointsId: 'ethena',
  pointsMultiplier: 1,
  collateralId: CollateralId.SUSDE,
  marketId: MarketId.ETH,
  contractTokenName: 'sUSDePrincipal Protected Bull Call Spread',
  isEarlyDeposit: false,
  strategyId: 'LongPrincipalProtected',
  isRfq: true,
  subaccountId: isTestnet ? 77552 : 10144,
  authHeaders: isTestnet
    ? {
        'X-LyraWallet': '0x4BAC0257C1a5be3814653789C6e60afF2F5f1DaD',
        'X-LyraTimestamp': '1722977193374',
        'X-LyraSignature':
          'a11eedc770c923ddab533079264b5689c0a5cdf4c2a6989a3b54f47f3fb7a61a0075ae6b5e666724e151bd09358fd19fa31a0fca9c0e1b7a178223c0cf6324451b',
      }
    : {
        'X-LyraWallet': '0x0b4eD379da8eF4FCF06F697c5782CA7b4c3E505E',
        'X-LyraTimestamp': '1719551753809',
        'X-LyraSignature':
          '0xc0852e937d1935ebe3c9c1e139f4364953a90b5ac92cc1a7c42db9f9a49d2ace3841d5c0e4e1dc2da696c1ede4053716972e320c76cd0fce38c81b9b9029aa831c',
      },
}

export const weETHCSConfig: YieldTokenConfig = {
  id: YieldTokenId.WEETHCS,
  addresses: isTestnet ? weETHCSTestnetAddresses : weETHCSMainnetAddresses,
  pointsId: 'lrt_etherfi_callspread',
  integratorPointsId: 'ethena',
  pointsMultiplier: 1,
  collateralId: CollateralId.WEETH,
  marketId: MarketId.ETH,
  contractTokenName: 'weETHPrincipal Protected Bull Call Spread',
  isEarlyDeposit: true,
  startExecutingTimestampUTC: undefined, // set when crosses $500k TVL
  strategyId: 'CoveredCallSpread',
  isEigenLayer: true,
  showPerformanceChart: true,
  // TO DO: Uncomment when isEarlyDeposit is false @mypalvikram
  // subaccountId: isTestnet ? 78188 : 10301,
  // authHeaders: isTestnet
  //   ? {
  //       'X-LyraWallet': '0x4900D5BbB97cea689C301E1805837a78eeBD8573',
  //       'X-LyraTimestamp': '1724107031986',
  //       'X-LyraSignature':
  //         'b72d5ea0de81ea2625e67f5f55b70161779394240b38ada3c02314180ae7c0f34396dd492bbf827524631acf46017828ce8e085e73f91721aaa01eaeee043cc01b',
  //     }
  //   : {
  //       'X-LyraWallet': '0x12ab0242b3e8d4502FebCED6C2d52fD23F7262af',
  //       'X-LyraTimestamp': '1719551753809',
  //       'X-LyraSignature':
  //         '0xc0852e937d1935ebe3c9c1e139f4364953a90b5ac92cc1a7c42db9f9a49d2ace3841d5c0e4e1dc2da696c1ede4053716972e320c76cd0fce38c81b9b9029aa831c',
  //     },
}

export const weETHBULLConfig: YieldTokenConfig = {
  id: YieldTokenId.WEETHBULL,
  addresses: isTestnet ? weETHBULLTestnetAddresses : weETHBULLMainnetAddresses,
  pointsId: 'lrt_etherfi_longpp',
  integratorPointsId: 'ethena',
  pointsMultiplier: 1,
  collateralId: CollateralId.WEETH,
  marketId: MarketId.ETH,
  contractTokenName: 'weETH Covered Call Spread',
  isEarlyDeposit: false,
  strategyId: 'LongPrincipalProtected',
  isEigenLayer: true,
  isRfq: true,
  subaccountId: isTestnet ? 78186 : 10303,
  authHeaders: isTestnet
    ? {
        'X-LyraWallet': '0xfdb2a80b21Ed2D6D738e2a4221931A343675382A',
        'X-LyraTimestamp': '1724107363626',
        'X-LyraSignature':
          'e39894257b633ccad2092da89544e6e8e20647fc57d4d4ef74dd39df5c3c487f54bf54283ce3da664b91c7a043c5afb074c285d10a2ec8e03ef15b04d554f1bb1c',
      }
    : {
        'X-LyraWallet': '0xe48cdbe3A233Ea577c9ee6959801e7730e516d1A',
        'X-LyraTimestamp': '1719551753809',
        'X-LyraSignature':
          '0xc0852e937d1935ebe3c9c1e139f4364953a90b5ac92cc1a7c42db9f9a49d2ace3841d5c0e4e1dc2da696c1ede4053716972e320c76cd0fce38c81b9b9029aa831c',
      },
}

export const LBTCPSConfig: YieldTokenConfig = {
  id: YieldTokenId.LBTCPS,
  addresses: isTestnet ? LBTCPSTestnetAddresses : LBTCPSMainnetAddresses,
  pointsId: 'lombard_lbtc_ps',
  integratorPointsId: 'lombard',
  pointsMultiplier: 3,
  collateralId: CollateralId.LBTC,
  marketId: MarketId.BTC,
  contractTokenName: 'LBTC Covered Put Spread',
  isEarlyDeposit: true,
  startExecutingTimestampUTC: undefined, // set when crosses $500k TVL
  strategyId: 'CoveredPutSpread',
  isLombard: true,
  isBabylon: true,
  isFeatured: true,
  showPerformanceChart: true,
  // subaccountId: isTestnet ? 78208 : 10628,
  // authHeaders: isTestnet
  //   ? {
  //       'X-LyraWallet': '0x49B9C82582B9916dE295D98b0c55373c300BbaEa',
  //       'X-LyraTimestamp': '1725571470068',
  //       'X-LyraSignature':
  //         '4d931b60819cf8cb2adeced7d0b0e3fc0ffe36698673386d95a8bf1cd74222fb28c154f8063a4aef64067edf04dad268374dbbf7495cd7b9ac7ff8b2b148979a1c',
  //     }
  //   : {
  //       'X-LyraWallet': '0x5Fc48A32437Ff4BBab2A22646c3c9344ba003971',
  //       'X-LyraTimestamp': '1719551753809',
  //       'X-LyraSignature':
  //         '0xc0852e937d1935ebe3c9c1e139f4364953a90b5ac92cc1a7c42db9f9a49d2ace3841d5c0e4e1dc2da696c1ede4053716972e320c76cd0fce38c81b9b9029aa831c',
  //     },
}

export const LBTCCSConfig: YieldTokenConfig = {
  id: YieldTokenId.LBTCCS,
  addresses: isTestnet ? LBTCCSTestnetAddresses : LBTCCSMainnetAddresses,
  pointsId: 'lombard_lbtc_cs',
  integratorPointsId: 'lombard',
  pointsMultiplier: 3,
  collateralId: CollateralId.LBTC,
  marketId: MarketId.BTC,
  contractTokenName: 'LBTC Covered Call Spread',
  isEarlyDeposit: false,
  strategyId: 'CoveredCallSpread',
  isLombard: true,
  isBabylon: true,
  isFeatured: true,
  showPerformanceChart: true,
  subaccountId: isTestnet ? 78207 : 10629,
  authHeaders: isTestnet
    ? {
        'X-LyraWallet': '0x65410Dd3A47f7cdfFd0486D45688F00B142029D7',
        'X-LyraTimestamp': '1725571574676',
        'X-LyraSignature':
          'b1431227e4f567ff1d6b0e25419f7f8455908a3a5d5865e2d1deb53f0c6e3c2d3d4c413c8418fe5d57e3166a24f3c969e6a8bcb3e3d43f74a7449622e96913d01c',
      }
    : {
        'X-LyraWallet': '0xbCab1f8BbA323BC55EA8cfaC34edAcf8DBE92dD4',
        'X-LyraTimestamp': '1719551753809',
        'X-LyraSignature':
          '0xc0852e937d1935ebe3c9c1e139f4364953a90b5ac92cc1a7c42db9f9a49d2ace3841d5c0e4e1dc2da696c1ede4053716972e320c76cd0fce38c81b9b9029aa831c',
      },
}

export const yieldTokenConfigs: Record<YieldTokenId, YieldTokenConfig> = {
  [YieldTokenId.RSWETHC]: rswETHCConfig,
  [YieldTokenId.WEETHC]: weETHCConfig,
  [YieldTokenId.RSETHC]: rsETHCConfig,
  [YieldTokenId.SUSDEBULL]: sUSDeBULLConfig,
  [YieldTokenId.WEETHBULL]: weETHBULLConfig,
  [YieldTokenId.WEETHCS]: weETHCSConfig,
  [YieldTokenId.LBTCCS]: LBTCCSConfig,
  [YieldTokenId.LBTCPS]: LBTCPSConfig,
}

export const getEmptyYieldTokens = <T>(
  getEmptyValue: (id: YieldTokenId) => T
): Record<YieldTokenId, T> =>
  Object.values(YieldTokenId).reduce(
    (acc, token) => {
      acc[token] = getEmptyValue(token)
      return acc
    },
    {} as Record<YieldTokenId, T>
  )
