import { Address } from 'viem'

import tryRequest from './tryRequest'
import {
  PublicCreateAccountParamsSchema,
  PublicCreateAccountResponseSchema,
} from './types/public.create_account'

export default async function createAccount({
  address,
  ownerAddress,
}: {
  address: Address
  ownerAddress?: Address
}): Promise<PublicCreateAccountResponseSchema> {
  const params: PublicCreateAccountParamsSchema = {
    wallet: address,
    scw_owner: ownerAddress,
  }
  return await tryRequest<PublicCreateAccountParamsSchema, PublicCreateAccountResponseSchema>(
    '/public/create_account',
    params
  )
}
