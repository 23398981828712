'use client'
import { useToastController } from '@lyra/core/components'
import Toast, { ToastProps } from '@lyra/core/components/Toast'
import {
  NAV_HEIGHT_DESKTOP,
  NAV_MOBILE_HEIGHT,
  TOAST_DEFAULT_DURATION,
} from '@lyra/web/constants/layout'
import { Toast as TamaguiToast, ToastViewport } from '@tamagui/toast'
import React, { useCallback, useMemo, useState } from 'react'

import emptyFunction from '../../utils/emptyFunction'
import generateUniqueId from '../../utils/getUniqueId'

type Props = {
  children?: React.ReactNode
}

export type ToastContext = {
  toasts: Record<string, ToastProps>
  createToast: (toastOptions: Omit<ToastProps, 'id'>) => string
  hideToast: (id: string) => void
}

export const ToastContext = React.createContext<ToastContext>({
  toasts: {},
  createToast: emptyFunction as any,
  hideToast: emptyFunction as any,
})

export default function ToastProvider({ children }: Props) {
  const toastController = useToastController()

  const [toasts, setToasts] = useState<Record<string, ToastProps>>({})

  const hideToast = useCallback((id: string) => {
    setToasts((toasts) => {
      const toastsCopy = { ...toasts }
      delete toastsCopy[id]
      return toastsCopy
    })
  }, [])

  const createToast = useCallback(
    (data: Omit<ToastProps, 'id'>) => {
      const id = generateUniqueId()
      const duration = data.duration ?? TOAST_DEFAULT_DURATION
      setToasts((toasts) => ({ ...toasts, [id]: { ...data, duration, id } }))
      toastController.show(id)
      if (duration && duration !== Infinity) {
        setTimeout(() => hideToast(id), duration)
      }
      return id
    },
    [hideToast, toastController]
  )

  const value = useMemo(() => {
    return {
      toasts,
      createToast,
      hideToast,
    }
  }, [toasts, createToast, hideToast])

  return (
    <ToastContext.Provider value={value}>
      <ToastViewport
        multipleToasts
        flexDirection="column"
        $mobile={{
          top: NAV_MOBILE_HEIGHT,
          right: 0,
          left: 0,
          rowGap: '$2',
          paddingTop: '$2',
        }}
        $desktop={{
          top: NAV_HEIGHT_DESKTOP,
          right: '$3',
          rowGap: '$2',
          paddingTop: '$2',
        }}
      />
      {Object.values(toasts).map((toast) => {
        return (
          <TamaguiToast
            key={toast.id}
            duration={toast.duration ?? TOAST_DEFAULT_DURATION}
            unstyled
            opacity={1}
            scale={1}
            $desktop={{
              minWidth: 320,
              maxWidth: 320,
            }}
            $mobile={{
              minWidth: '100vw',
              maxWidth: '100vw',
              paddingHorizontal: '$3',
            }}
          >
            <Toast
              id={toast.id}
              icon={toast.icon}
              title={toast.title}
              description={toast.description}
              color={toast.color}
              duration={toast.duration ?? TOAST_DEFAULT_DURATION}
              onClose={() => hideToast(toast.id)}
              onPress={toast.onPress}
              width="100%"
            />
          </TamaguiToast>
        )
      })}
      {children}
    </ToastContext.Provider>
  )
}
