'use client'

import { useConnectWallet, useWallets } from '@privy-io/react-auth'
import { useSetActiveWallet } from '@privy-io/wagmi'
import { createContext, useCallback, useMemo } from 'react'
import { Address } from 'viem'
import { useAccount, useWalletClient } from 'wagmi'

import { LyraWalletClient, WalletType } from '../../constants/wallet'
import useAuth from '../../hooks/useAuth'
import emptyFunction from '../../utils/emptyFunction'

type EoaContext = {
  isReady: boolean
  address: Address | undefined
  chainId: number | undefined
  walletClient: LyraWalletClient | undefined
  isConnected: boolean
  reconnectWallet: () => void
}

export const EoaContext = createContext<EoaContext>({
  isReady: false,
  address: undefined,
  chainId: undefined,
  walletClient: undefined,
  isConnected: false,
  reconnectWallet: emptyFunction as any,
})

export default function EoaProvider({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, user, session } = useAuth()

  const targetOwnerAddress = user?.ownerAddress

  const externalWalletClientQuery = useWalletClient()
  const walletClient: LyraWalletClient | undefined = externalWalletClientQuery.data
  const status = externalWalletClientQuery.status

  const { address: connectedOwnerAddress, chainId } = useAccount()

  const { wallets } = useWallets()
  const { setActiveWallet } = useSetActiveWallet()

  const { connectWallet } = useConnectWallet({
    onSuccess: (baseWallet) => {
      const targetWallet = wallets.find((wallet) => wallet.address === baseWallet.address)
      if (
        targetWallet &&
        targetWallet.address !== connectedOwnerAddress &&
        targetOwnerAddress &&
        targetWallet.address === targetOwnerAddress
      ) {
        console.debug('privy: onConnectWallet setActiveWallet', {
          targetWallet,
          connectedOwnerAddress,
        })
        setActiveWallet(targetWallet)
      }
    },
  })

  const reconnectWallet = useCallback(async () => {
    if (!targetOwnerAddress) {
      throw new Error('Not authenticated')
    }

    connectWallet({ suggestedAddress: targetOwnerAddress })
  }, [connectWallet, targetOwnerAddress])

  const address =
    isAuthenticated &&
    connectedOwnerAddress === user.ownerAddress &&
    session.walletType === WalletType.External
      ? targetOwnerAddress
      : undefined

  const isReady = status !== 'pending'

  const value = useMemo(
    () => ({
      address,
      walletClient,
      chainId,
      isConnected: !!address,
      reconnectWallet,
      isReady,
    }),
    [address, walletClient, chainId, isReady, reconnectWallet]
  )

  return <EoaContext.Provider value={value}>{children}</EoaContext.Provider>
}
