import fetchAllPortfolios from '@lyra/core/api/private/fetchAllPortfolios'
import { LyraAuthHeaders } from '@lyra/core/constants/api'
import { Subaccount } from '@lyra/web/constants/subaccount'

export default async function fetchSubaccounts(
  address: string,
  authHeaders: LyraAuthHeaders
): Promise<Subaccount[]> {
  const { result } = await fetchAllPortfolios({ wallet: address }, authHeaders)
  return result
}
